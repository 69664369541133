/***************ENVIRONMENTS***************************************************** */
const environment = {
  Development: "dev",
  Test: "test",
  "Pre-Prod": "preprod",
  Int: "int",
  Prod: "prod",
  Production: "production",
};

const region =
  process.env.REACT_APP_WebAdminEnvironment === "Prod" ||
  process.env.REACT_APP_WebAdminEnvironment === "Production"
    ? "koreacentral"
    : "westeurope";

let baseUrl;
let recoBaseUrl;
if (process.env.REACT_APP_WebAdminEnvironment === "Development") {
  baseUrl = "https://care-int.mercedes.me/admin";
  // baseUrl = `https://daimler-care4me-${
  //   environment[process.env.REACT_APP_WebAdminEnvironment]
  //   }.${region}.cloudapp.azure.com/admin`;
  //baseUrl = "http://localhost:3000";
  // recoBaseUrl = `https://daimler-care4me-${
  //   environment[process.env.REACT_APP_WebAdminEnvironment]
  // }.${region}.cloudapp.azure.com/re/ui`;
  recoBaseUrl = "https://care-int.mercedes.me/admin/re/ui";
} else if (process.env.REACT_APP_WebAdminEnvironment === "Production") {
  baseUrl = "https://care.mercedes.me/admin";
  recoBaseUrl = "https://care.mercedes.me/re/ui";
} else {
  baseUrl = `https://care-${
    environment[process.env.REACT_APP_WebAdminEnvironment]
  }.mercedes.me/admin`;
  recoBaseUrl = `https://care-${
    environment[process.env.REACT_APP_WebAdminEnvironment]
  }.mercedes.me/re/ui`;
}

export { baseUrl, recoBaseUrl };

export const blobStorageBaseUrl = `https://care4me${
  environment[process.env.REACT_APP_WebAdminEnvironment]
}storage.blob.core.windows.net/images`;

// AUTHORIZATION
export const login = "api/authorization/login";
export const logout = "api/authorization/logout";
export const userInfo = "api/authorization/userInfo";
export const refreshToken = "api/authorization/refreshToken";
export const exchangeToken = "api/authorization/exchangeToken";

// OFFER CATEGORY

export const getOfferCategoryTypes = "/api/OfferCategory/getoffercategorytypes";
export const getOfferCategories = "/api/OfferCategory/getoffercategories";
export const updateOfferCategory = "/api/OfferCategory/updateoffercategory";
export const getLtrCarModels = "api/OfferCategory/carModels";
export const getLtrCarClasses = "api/OfferCategory/CarClasses";
export const updateLtr = "api/OfferCategory/carModels";

// OFFER

export const getAllOffersByTypeId = "api/Offer/GetAllOffersByTypeId";

export const deleteBenefitOfferById = "api/Offer/DeleteBenefitOffer";
export const deleteAfterSalesOfferById = "api/Offer/DeleteAfterSalesOffer";
export const deleteEventOfferById = "api/Offer/DeleteEventOffer";

export const createEventOffer = "api/Offer/CreateEventOffer";
export const createBenefitOffer = "api/Offer/CreateBenefitOffer";
export const createAfterSalesOffer = "api/Offer/CreateAfterSalesOffer";

export const getEventOfferDetail = "api/Offer/GetEventOfferDetail";
export const getBenefitOfferDetail = "api/OFfer/GetBenefitOfferDetail";
export const getAfterSalesOfferDetail = "api/Offer/GetAfterSalesOfferDetail";
export const getTestDriveOfferDetail = "api/Offer/getTestDriveOfferDetail";

export const updateEventOffer = "api/Offer/UpdateEventOffer";
export const updateBenefitOffer = "api/Offer/UpdateBenefitOffer";
export const patchBenefitOffer = "api/Offer/Benefits";
export const updateAfterSalesOffer = "api/Offer/UpdateAfterSalesOffer";
export const updateTestDriveOffer = "api/Offer/UpdateTestDriveOffer";

export const getRecommendationTags = "api/offer/GetBenefitRecommendationTags";
export const getFilterTags = "api/offer/FilterTags";

// FILE

export const uploadImages = "api/File/UploadImages";
export const uploadInvoices = "api/File/UploadInvoice";

// LANGUAGE

export const getSupportedLanguages = "api/Configuration/GetSupportedLanguages";

// LOYALTY PROGRAM

export const createTransaction = "api/Loyalty/CreateTransaction";
export const getTransaction = "api/Loyalty/GetTransactionByUserId";
export const cancelTransaction = "api/Loyalty/CancelTransaction";
export const getTransactionByDealer = "api/Loyalty/GetTransactionByDealerId";
export const IsDealerInTransactionHistory =
  "api/Loyalty/IsDealerInTransactionHistory";
export const GetPagedTransactions = "api/Loyalty/GetPagedTransactions";
export const GetBlobSasUri = "api/Loyalty/GetBlobSasUri";
export const GetPagedVoucherCodeList = "api/Loyalty/PagedVoucherCodesByDate";
export const VoucherCodesByCode = "api/Loyalty/VoucherCodesByCode";
export const UpdateVoucherCodeStatus = "api/Loyalty/UpdateVoucherCodeStatus";

// LOG EXPORT

export const getAllAuditLogs = "api/Audit/getAllLogs";
export const getAuditLogs = "api/Audit/logs";
export const getAuditLogById = "api/Audit/getById";

// LOGIN LOGOUT LOG

export const logLoginEvent = "api/authorization/login";
export const logLogoutEvent = "api/authorization/logout";

// CUSTOMER DB

export const getAllUsers = "api/User/getAllUsers";
export const deleteUserByUuid = "api/User/DeleteUserByUuid";
export const updateUsers = "api/User/UpdateUsers";
export const getPagedUsers = "api/user/getPagedUsers";
export const getOneUser = "api/user/getOneUser";

// COMPANY

export const companyEndpoint = "api/company"; // POST, GET, PUT, DELETE

// Booking History

export const getBookingHistory = "api/Booking/getAll";
export const branchEndpoint = "api/branch";

//Feedback
export const getPagedGeneralFeedbacks = "api/feedback/GetPagedGeneralFeedbacks";
export const getaverageGeneralFeedback = "api/feedback/averageGeneralFeedback";

//What Is New

export const getWhatIsNewByDeviceTypeId = "api/whatisnew/GetWhatIsNews";
export const upsertVersion = "api/whatisnew/UpsertVersion";
export const updateStatus = "api/whatisnew/ChangeStatus";

//OFFER ORDER

export const updateOffersOrder = "api/Offer/UpdateEventOfferTileOrder";
export const updateAfterSalesOrder = "api/Offer/UpdateAfterSalesTileOrder";
