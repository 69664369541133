import * as Endpoints from "../const/endpoints";
import * as NetworkServices from "../services/networkServices";
import { uploadInvoices } from "./fileUpload";
import {
  getCurrentDateWithTimezone,
  getCurrentDateUTC,
  getGivenDateUTC,
} from "../helper/dateTimeHelper";
import {
  regexKrTelephoneNumber,
  regexMailControl,
  // regexTelephoneNumber,
} from "../const/regex";
import global from "../const/global";
import moment from "moment-timezone";
import _ from "lodash";
import { downloadFile } from "../helper/fileHelper";

// Actions

const HANDLE_CHANGE_ADD_POINTS = "reducer/HANDLE_CHANGE_ADD_POINTS";
const ADD_NEW_CUSTOMER = "reducer/ADD_NEW_CUSTOMER";
const DELETE_SELECTED_CUSTOMER = "reducer/DELETE_SELECTED_CUSTOMER";
const ADD_POINTS_TO_CUSTOMER = "reducer/ADD_POINTS_TO_CUSTOMER";
const VALIDATE_CUSTOMER_LIST = "reducer/VALIDATE_CUSTOMER_LIST";
const USER_NOT_FOUND = "reducer/USER_NOT_FOUND";
const DEALER_NOT_FOUND = "reducer/DEALER_NOT_FOUND";
const CREATE_TRANSACTION = "reducer/CREATE_TRANSACTION";
const SHOW_POINTS_NOT_ADDED_ERROR = "reducer/SHOW_POINTS_NOT_ADDED_ERROR";
const SHOW_POINTS_NOT_ADDED_TO_INACTIVE_USER_ERROR =
  "reducer/SHOW_POINTS_NOT_ADDED_TO_INACTIVE_USER_ERROR";
const CLEAR_TRANSACTION_LIST = "reducer/CLEAR_TRANSACTION_LIST";
const CLEAR_CUSTOMER_LIST_FIELD_CONTROL =
  "reducer/CLEAR_CUSTOMER_LIST_FIELD_CONTROL";
const USER_EXIST = "reducer/USER_EXIST";
const DEALER_EXIST = "reducer/DEALER_EXIST";
const CLEAR_USER_LIST = "reducer/CLEAR_USER_LIST";
const GET_TRANSACTION_HISTORY = "reducer/GET_TRANSACTION_HISTORY";
const REMOVE_TRANSACTION = "reducer/REMOVE_TRANSACTION";
const TRANSACTION_CANCEL = "reducer/CANCEL_TRANSACTION";
const CLEAR_TRANSACTION_HISTORY = "reducer/CLEAR_TRANSACTION_HISTORY";
const SET_COSTUMER_AND_TRANSACTION = "reducer/SET_COSTUMER_AND_TRANSACTION";
const GET_DEALERS = "reducer/GET_DEALERS";
const GET_PAGED_ALL_TRANSACTIONS = "reducer/GET_PAGED_ALL_TRANSACTIONS";
const CHANGE_HISTORY_ACTIVE_PAGE = "reducer/CHANGE_HISTORY_ACTIVE_PAGE";
const CLEAR_PAGINATION = "reducer/CLEAR_PAGINATION";
const DOWNLOAD_INVOICE = "reducer/DOWNLOAD_INVOICE";
const SEARCH_VOUCHERCODE = "reducer/SEARCH_VOUCHERCODE";
const CLEAR_VOUCHERCODE = "reducer/CLEAR_VOUCHERCODE";
const VOUCHER_NOT_FOUND = "reducer/VOUCHER_NOT_FOUND";
const VOUCHER_EXIST = "reducer/VOUCHER_EXIST";
const CLEAR_VOUCHERCODE_LIST = "reducer/CLEAR_VOUCHERCODE_LIST";
const ADD_VOUCHERCODE = "reducer/ADD_VOUCHERCODE";
const REMOVE_VOUCHERCODE = "reducer/REMOVE_VOUCHERCODE";
const GET_PAGED_ALL_VOUCHERCODES = "reducer/GET_PAGED_ALL_VOUCHERCODES";
const GET_ALL_VOUCHERCODES = "reducer/GET_ALL_VOUCHERCODES";
const CLEAR_ISFILLED_VOUCHERCODEEXPORT =
  "reducer/CLEAR_ISFILLED_VOUCHERCODEEXPORT";
const GET_ALL_TRANSACTIONS = "reducer/GET_ALL_TRANSACTIONS";
const CLEAR_ISFILLED_TRANSACTIONHISTORY_EXPORT =
  "reducer/CLEAR_ISFILLED_TRANSACTIONHISTORY_EXPORT";
const HANDLE_CHANGE_VOUCHER_STATUS = "reducer/HANDLE_CHANGE_VOUCHER_STATUS";
const UPDATE_VOUCHER_CODE_STATUS = "reducer/UPDATE_VOUCHER_CODE_STATUS";

// Reducers
// const initialCustomerList = [
//     {
//         order: 0,
//         providerID: null,
//         location: null,
//         customerRecordType: '0',
//         emailAddress: null,
//         phoneNumber: null,
//         loyaltyCardNumber: null,
//         serviceValue: null,
//         changePoints: 0,
//         serviceDate: getCurrentDateWithTimezone(),
//         transactionType: null,
//         invoice: null,
//         actionType: 0,
//     }
// ];
export default (
  state = {
    statusPointsNotAddedError: false,
    customerListFieldControl: [],
    userNotFound: false,
    customerList: [],
    customerListDB: [],
    dealerList: [],
    voucherCodeList: [],
    selectedVoucherCodeList: [],
    voucherSearchList: [],
    userMembershipStatus: [],
    pointsNotAddedToActiveUser: false,
    userExitValue: false,
    transactionHistory: [],
    transactionLoad: false,
    transactionCancelLoader: false,
    transactionCancelError: null,
    dealerNotFound: false,
    dealerExistValue: false,
    loadingGetAllTransaction: false,
    loadingVoucherCode: false,
    pagination: global.paginationModel,
    isFilledVoucherCodeExport: false,
  },
  action
) => {
  switch (action.type) {
    case HANDLE_CHANGE_ADD_POINTS:
      return {
        ...state,
        customerList: action.customerList,
      };
    case ADD_POINTS_TO_CUSTOMER:
      return {
        ...state,
        loadingAddPointsToCustomer: action.loadingAddPointsToCustomer,
        error: action.error,
      };
    case ADD_NEW_CUSTOMER:
    case DELETE_SELECTED_CUSTOMER:
      return {
        ...state,
        customerList: action.customerList,
      };
    case VALIDATE_CUSTOMER_LIST:
      return {
        ...state,
        customerListFieldControl: action.customerListFieldControl,
        userMembershipStatus: action.userMembershipStatus,
        deductedMoreThanAvailable: action.deductedMoreThanAvailable,
      };
    case USER_NOT_FOUND:
      return {
        ...state,
        userNotFound: action.value,
      };
    case USER_EXIST:
      return {
        ...state,
        userExitValue: action.userExitValue,
      };
    case DEALER_NOT_FOUND:
      return {
        ...state,
        dealerNotFound: action.value,
      };
    case DEALER_EXIST:
      return {
        ...state,
        dealerExistValue: action.dealerExistValue,
      };
    case CREATE_TRANSACTION:
      return {
        ...state,
        loadingCreateTransaction: action.loadingCreateTransaction,
        transactionStatus: action.transactionStatus,
        customerList: action.customerList,
        customerListFieldControl: action.customerListFieldControl,
        deductedMoreThanAvailable: action.deductedMoreThanAvailable,
      };
    case SHOW_POINTS_NOT_ADDED_ERROR:
      return {
        ...state,
        statusPointsNotAddedError: action.statusPointsNotAddedError,
        loadingCreateTransaction: action.loadingCreateTransaction,
      };
    case SHOW_POINTS_NOT_ADDED_TO_INACTIVE_USER_ERROR:
      return {
        ...state,
        pointsNotAddedToActiveUser: action.pointsNotAddedToActiveUser,
        userMembershipStatus: action.userMembershipStatus,
        loadingCreateTransaction: action.loadingCreateTransaction,
      };
    case CLEAR_TRANSACTION_LIST:
      return {
        ...state,
        customerList: action.customerList,
        customerListFieldControl: action.customerListFieldControl,
        userMembershipStatus: action.userMembershipStatus,
      };
    case CLEAR_CUSTOMER_LIST_FIELD_CONTROL:
      return {
        ...state,
        customerListFieldControl: action.customerListFieldControl,
        userMembershipStatus: action.userMembershipStatus,
        deductedMoreThanAvailable: action.deductedMoreThanAvailable,
      };
    case CLEAR_USER_LIST:
      return {
        ...state,
        customerList: [],
      };
    case GET_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: action.transactionHistory,
        transactionLoad: action.transactionLoad,
      };
    case REMOVE_TRANSACTION:
      return {
        ...state,
        customerList: action.customerList,
        transactionHistory: action.transactionHistory,
      };
    case TRANSACTION_CANCEL:
      return {
        ...state,
        transactionCancelLoader: action.transactionCancelLoader,
        transactionCancelError: action.transactionCancelError,
      };
    case CLEAR_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: action.transactionHistory,
      };
    case SET_COSTUMER_AND_TRANSACTION:
      return {
        ...state,
        customerList: action.customerList,
        transactionHistory: action.transactionHistory,
      };
    case GET_DEALERS:
      return {
        ...state,
        customerListDB: _.cloneDeep(action.customerListDB),
        loadingGetCustomers: action.loadingGetCustomers,
        userDeleted: _.cloneDeep(action.userDeleted),
        userUpdated: _.cloneDeep(action.userUpdated),
        showUserNotBeDeleted: action.showUserNotBeDeleted,
        showUserNotBeUpdated: action.showUserNotBeUpdated,
        showCannotDeleteActiveUsersError:
          action.showCannotDeleteActiveUsersError,
        error: action.error,
        clearFilterButtonClicked: action.clearFilterButtonClicked,
      };
    case GET_PAGED_ALL_TRANSACTIONS:
      return {
        ...state,
        transactionHistory: action.transactionHistory,
        loadingGetAllTransaction: action.loadingGetAllTransaction,
        pagination: action.pagination,
        transactionHistoryFromDate: action.transactionHistoryFromDate,
        transactionHistoryToDate: action.transactionHistoryToDate,
      };
    case GET_ALL_TRANSACTIONS:
      return {
        ...state,
        transactionHistoryForExport: action.transactionHistoryForExport,
        loadingTransactionHistoryExport: action.loadingTransactionHistoryExport,
        isFilledTransactionHistoryExport:
          action.isFilledTransactionHistoryExport,
      };
    case CLEAR_ISFILLED_TRANSACTIONHISTORY_EXPORT:
      return {
        ...state,
        isFilledTransactionHistoryExport:
          action.isFilledTransactionHistoryExport,
      };
    case CHANGE_HISTORY_ACTIVE_PAGE:
      return {
        ...state,
        pagination: action.pagination,
      };
    case CLEAR_PAGINATION:
      return {
        ...state,
        pagination: global.paginationModel,
      };
    case DOWNLOAD_INVOICE:
      return {
        ...state,
      };
    case SEARCH_VOUCHERCODE:
      return {
        ...state,
        loadingVoucherCode: action.loadingVoucherCode,
        voucherSearchList: action.voucherSearchList,
      };
    case CLEAR_VOUCHERCODE:
      return {
        ...state,
        voucherSearchList: action.voucherSearchList,
      };
    case VOUCHER_NOT_FOUND:
      return {
        ...state,
        voucherNotFound: action.voucherNotFound,
      };
    case VOUCHER_EXIST:
      return {
        ...state,
        voucherExistValue: action.voucherExistValue,
      };
    case ADD_VOUCHERCODE:
      return {
        ...state,
        voucherCodeList: action.voucherCodeList,
        selectedVoucherCodeList: action.selectedVoucherCodeList,
      };
    case CLEAR_VOUCHERCODE_LIST:
      return {
        ...state,
        voucherCodeList: action.voucherCodeList,
        voucherCodeListForExport: action.voucherCodeListForExport,
        selectedVoucherCodeList: action.selectedVoucherCodeList,
      };
    case REMOVE_VOUCHERCODE:
      return {
        ...state,
        voucherCodeList: action.voucherCodeList,
        selectedVoucherCodeList: action.selectedVoucherCodeList,
      };
    case GET_PAGED_ALL_VOUCHERCODES:
      return {
        ...state,
        voucherCodeList: action.voucherCodeList,
        loadingVoucherCode: action.loadingVoucherCode,
        pagination: action.pagination,
        voucherCodeListFromDate: action.voucherCodeListFromDate,
        voucherCodeListToDate: action.voucherCodeListToDate,
      };
    case GET_ALL_VOUCHERCODES:
      return {
        ...state,
        voucherCodeListForExport: action.voucherCodeListForExport,
        loadingVoucherCodeExport: action.loadingVoucherCodeExport,
        isFilledVoucherCodeExport: action.isFilledVoucherCodeExport,
      };
    case CLEAR_ISFILLED_VOUCHERCODEEXPORT:
      return {
        ...state,
        isFilledVoucherCodeExport: action.isFilledVoucherCodeExport,
      };
    case HANDLE_CHANGE_VOUCHER_STATUS:
      return {
        ...state,
        voucherCodeList: action.voucherCodeList,
        selectedVoucherCodeList: action.selectedVoucherCodeList,
      };
    case UPDATE_VOUCHER_CODE_STATUS:
      return {
        ...state,
        voucherCodeList: action.voucherCodeList,
        selectedVoucherCodeList: action.selectedVoucherCodeList,
      };
    default:
      return {
        ...state,
      };
  }
};

// Action Creators

export function handleChangeAddPoints(name, value, order) {
  if (
    value === "" &&
    name !== "emailAddress" &&
    name !== "phoneNumber" &&
    name !== "location"
  ) {
    value = null;
  }
  return function (dispatch, getState) {
    let editedCustomerList = getState().loyaltyProgram.customerList;
    if (editedCustomerList.length > 0) {
      if (name === "serviceValue") {
        if (value === null) {
          editedCustomerList[order][name] = null;
          editedCustomerList[order]["changePoints"] = 0;
        } else {
          editedCustomerList[order][name] = value;

          if (editedCustomerList[order]["transactionType"] === null) {
            editedCustomerList[order]["changePoints"] = 0;
          } else {
            // you can calculate according to transactionType here but only transactionType is service currently so just calculating it
            editedCustomerList[order]["changePoints"] = Math.ceil(value * 0.01);
          }
        }
      } else {
        editedCustomerList[order][name] = value;
      }
      if (name === "customerRecordType") {
        editedCustomerList[order].phoneNumber = "";
        editedCustomerList[order].emailAddress = "";
        editedCustomerList[order].loyaltyCardNumber = "";
      }
      // also calculate changePoints when transactionType changes
      if (name === "transactionType") {
        let serviceValue = editedCustomerList[order]["serviceValue"];
        if (value === null) {
          editedCustomerList[order]["changePoints"] = 0;
        } else {
          editedCustomerList[order]["changePoints"] = Math.ceil(
            serviceValue * 0.01
          );
        }
      }

      if (name === "invoice") {
        editedCustomerList[order].previewInvoice = value;
      }
    }
    return dispatch({
      type: HANDLE_CHANGE_ADD_POINTS,
      customerList: editedCustomerList,
    });
  };
}

export function handleChangeDeductPoints(name, value, order) {
  if (
    value === "" &&
    name !== "emailAddress" &&
    name !== "phoneNumber" &&
    name !== "location"
  ) {
    value = null;
  }
  return function (dispatch, getState) {
    let editedCustomerList = getState().loyaltyProgram.customerList;
    if (editedCustomerList.length > 0) {
      if (name === "serviceValue") {
        if (value === null) {
          editedCustomerList[order][name] = null;
          editedCustomerList[order]["changePoints"] = 0;
        } else {
          editedCustomerList[order][name] = value;
          editedCustomerList[order]["changePoints"] = value;
        }
      } else {
        editedCustomerList[order][name] = value;
      }
      if (name === "customerRecordType") {
        editedCustomerList[order].phoneNumber = "";
        editedCustomerList[order].emailAddress = "";
        editedCustomerList[order].loyaltyCardNumber = "";
      }
      if (name === "invoice") {
        editedCustomerList[order].previewInvoice = value;
      }
    }
    return dispatch({
      type: HANDLE_CHANGE_ADD_POINTS,
      customerList: editedCustomerList,
    });
  };
}

export function addNewCustomer() {
  return function (dispatch, getState) {
    let newCustomerList = getState().loyaltyProgram.customerList;
    if (newCustomerList.length <= 15) {
      newCustomerList.push({
        order: newCustomerList.length,
        providerID: getState().auth.userInfo.sub,
        location: getState().auth.userInfo.location_name || " ",
        transactionIssuer: getState().auth.userInfo.working_org_alias,
        customerRecordType: "0",
        emailAddress: "",
        phoneNumber: "",
        loyaltyCardNumber: "",
        serviceValue: null,
        changePoints: 0,
        serviceDate: getCurrentDateWithTimezone(),
        transactionType: null,
        invoice: null,
        actionType: 0,
        transactionFromDate: getGivenDateUTC(),
        transactionToDate: getCurrentDateUTC(),
        previewInvoice: null,
      });
    }
    return dispatch({
      type: ADD_NEW_CUSTOMER,
      customerList: newCustomerList,
    });
  };
}

export function addNewCustomerDeduct({
  emailAddress,
  phoneNumber,
  loyaltyCardNumber,
  points,
  customerRecordType,
  userId,
  dealerId,
}) {
  return function (dispatch, getState) {
    let newCustomerList = getState().loyaltyProgram.customerList;
    if (newCustomerList.length <= 15) {
      newCustomerList.push({
        order: newCustomerList.length,
        providerID: getState().auth.userInfo.sub,
        location: getState().auth.userInfo.location_name || " ",
        transactionIssuer: getState().auth.userInfo.working_org_alias,
        customerRecordType: customerRecordType,
        emailAddress: emailAddress !== null ? emailAddress : "",
        phoneNumber: phoneNumber !== null ? phoneNumber : "",
        loyaltyCardNumber: loyaltyCardNumber !== null ? loyaltyCardNumber : "",
        serviceValue: null,
        pointsAvailable: points,
        changePoints: 0,
        serviceDate: getCurrentDateWithTimezone(),
        transactionType: null,
        invoice: null,
        actionType: 1,
        userId: userId,
        transactionFromDate: getGivenDateUTC(moment().subtract(1, "M")),
        transactionToDate: getCurrentDateUTC(),
        dealerId: dealerId,
        previewInvoice: null,
      });
    }
    return dispatch({
      type: ADD_NEW_CUSTOMER,
      customerList: newCustomerList,
    });
  };
}

export function deleteSelectedCustomer(customer_order) {
  return function (dispatch, getState) {
    let newCustomerList = getState().loyaltyProgram.customerList;
    let newUserMembershipStatus =
      getState().loyaltyProgram.userMembershipStatus.splice(customer_order, 1);

    newCustomerList = newCustomerList.filter(
      (item) => item.order !== customer_order
    );

    newCustomerList.forEach((item) => {
      if (item.order > customer_order) {
        item.order--;
      }
    });
    return dispatch({
      type: DELETE_SELECTED_CUSTOMER,
      customerList: newCustomerList,
      userMembershipStatus: newUserMembershipStatus,
    });
  };
}

// Add points
export function validateCustomerList() {
  return function (dispatch, getState) {
    let customerListFieldControl = [];
    let customerList = getState().loyaltyProgram.customerList;
    let hasPhoneFormatError = false;

    customerList.forEach((item, key) => {
      switch (item.customerRecordType) {
        case "0":
          customerListFieldControl.push("customerRecordType" + key);
          break;
        case "1":
          // First check if phone number is empty
          if (item.phoneNumber.length === 0) {
            customerListFieldControl.push("customerRecord" + key);
          }
          // Then check for invalid format when phone is not empty
          else if (!regexKrTelephoneNumber.test(item.phoneNumber)) {
            customerListFieldControl.push("phoneNumberFormat" + key);
            hasPhoneFormatError = true; // Set flag to indicate phone format error
          }
          break;
        case "2":
          (item.emailAddress === "" ||
            !regexMailControl.test(item.emailAddress)) &&
            customerListFieldControl.push("customerRecord" + key);
          break;
        case "3":
          item.loyaltyCardNumber === "" &&
            customerListFieldControl.push("customerRecord" + key);
          break;
        default:
          break;
      }

      for (let field in item) {
        if (
          item[field] === null &&
          field !== "invoice" &&
          field !== "previewInvoice" &&
          field !== "providerID" &&
          field !== "location" &&
          field !== "transactionIssuer"
        ) {
          customerListFieldControl.push(field + key);
        }
      }
    });

    // Special handling for phone format errors
    if (hasPhoneFormatError) {
      // Make sure phoneNumberFormat is in the list for the error message display
      if (!customerListFieldControl.includes("phoneNumberFormat")) {
        customerListFieldControl.push("phoneNumberFormat");
      }
    }

    if (customerListFieldControl.length > 0) {
      return dispatch({
        type: VALIDATE_CUSTOMER_LIST,
        customerListFieldControl: customerListFieldControl,
        userMembershipStatus: [],
        hasPhoneFormatError: hasPhoneFormatError,
      });
    } else {
      dispatch(createTransaction(getState().loyaltyProgram.customerList));
    }
  };
}

export function validateCustomerListDeduct() {
  return function (dispatch, getState) {
    let customerListFieldControl = [];
    let customerList = getState().loyaltyProgram.customerList;
    var deductedMoreThanAvailable = false;
    customerList.forEach((item, key) => {
      switch (item.customerRecordType) {
        case "0":
          customerListFieldControl.push("customerRecordType" + key);
          break;
        case "1":
          item.phoneNumber === "" &&
            customerListFieldControl.push("customerRecord" + key);
          break;
        case "2":
          (item.emailAddress === "" ||
            !regexMailControl.test(item.emailAddress)) &&
            customerListFieldControl.push("customerRecord" + key);
          break;
        case "3":
          item.loyaltyCardNumber === "" &&
            customerListFieldControl.push("customerRecord" + key);
          break;
        default:
          break;
      }

      if (item.changePoints > item.pointsAvailable || item.changePoints === 0) {
        deductedMoreThanAvailable = true;
        customerListFieldControl.push("serviceValue" + key);
      }

      for (let field in item) {
        if (
          item[field] === null &&
          field !== "invoice" &&
          field !== "previewInvoice" &&
          field !== "providerID" &&
          field !== "location" &&
          field !== "transactionIssuer"
        ) {
          customerListFieldControl.push(field + key);
        }
      }
    });

    if (customerListFieldControl.length > 0) {
      return dispatch({
        type: VALIDATE_CUSTOMER_LIST,
        customerListFieldControl: customerListFieldControl,
        userMembershipStatus: [],
        deductedMoreThanAvailable: deductedMoreThanAvailable,
      });
    } else {
      dispatch(createTransaction(getState().loyaltyProgram.customerList));
    }
  };
}

export function createTransaction(transaction_list) {
  return function (dispatch, getState) {
    dispatch({
      type: CREATE_TRANSACTION,
      loadingCreateTransaction: true,
      transactionStatus: false,
      customerList: getState().loyaltyProgram.customerList,
      customerListFieldControl: [],
      deductedMoreThanAvailable: false,
    });
    let invoice_list = [];
    let transactionWithInvoices = false;
    transaction_list.forEach((transaction) => {
      if (transaction.invoice instanceof File) {
        invoice_list.push({
          invoice: transaction.invoice,
        });
        transactionWithInvoices = true;
      }
    });
    if (transactionWithInvoices) {
      let uploadFileAction = dispatch(
        uploadInvoices(prepareFormData(invoice_list))
      );
      return uploadFileAction.then((response) => {
        if (!response.error.status) {
          let count = 0;
          transaction_list.forEach((transaction) => {
            if (transaction.invoice !== null) {
              transaction.invoice = response.hashOfInvoices[count];
              count++;
            }
          });
          dispatch(requestCreateTransaction(transaction_list));
        } else {
          dispatch({
            type: CREATE_TRANSACTION,
            loadingCreateTransaction: false,
            transactionStatus: false,
            customerList: getState().loyaltyProgram.customerList,
            customerListFieldControl: [],
            deductedMoreThanAvailable: false,
          });
        }
      });
    } else {
      dispatch(requestCreateTransaction(transaction_list));
    }
  };
}

function requestCreateTransaction(transaction_list) {
  return function (dispatch, getState) {
    return NetworkServices.requestData(
      "POST",
      Endpoints.createTransaction,
      transaction_list,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1) {
          return dispatch({
            type: CREATE_TRANSACTION,
            loadingCreateTransaction: false,
            transactionStatus: true,
            customerList: [
              {
                order: 0,
                providerID: getState().auth.userInfo.sub,
                transactionIssuer: getState().auth.userInfo.working_org_alias,
                location: null,
                customerRecordType: "0",
                emailAddress: "",
                phoneNumber: "",
                loyaltyCardNumber: "",
                serviceValue: null,
                changePoints: 0,
                serviceDate: getCurrentDateWithTimezone(),
                transactionType: null,
                invoice: null,
                actionType: 0,
                deductedMoreThanAvailable: false,
                previewInvoice: null,
              },
            ],
            customerListFieldControl: [],
            userMembershipStatus: [],
          });
        } else {
          return dispatch({
            type: CREATE_TRANSACTION,
            loadingCreateTransaction: false,
            transactionStatus: false,
            customerList: getState().loyaltyProgram.customerList,
            customerListFieldControl:
              getState().loyaltyProgram.customerListFieldControl,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: CREATE_TRANSACTION,
          loadingCreateTransaction: false,
          customerList: getState().loyaltyProgram.customerList,
          customerListFieldControl: [],
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

function prepareFormData(invoice_list) {
  var formData = new FormData();
  invoice_list.forEach((invoice) => {
    if (invoice.invoice instanceof File || invoice.invoice instanceof Object) {
      formData.append("invoices", invoice.invoice);
    }
  });
  return formData;
}

export function showPointsNotAddedError(status) {
  return function (dispatch) {
    dispatch({
      type: SHOW_POINTS_NOT_ADDED_ERROR,
      statusPointsNotAddedError: status,
      loadingCreateTransaction: false,
    });
  };
}

export function showPointsNotAddedToInactiveUsersError(status, data) {
  return function (dispatch) {
    dispatch({
      type: SHOW_POINTS_NOT_ADDED_TO_INACTIVE_USER_ERROR,
      pointsNotAddedToActiveUser: status,
      userMembershipStatus: data,
      loadingCreateTransaction: false,
    });
  };
}

export function clearTransactionList() {
  return function (dispatch, getState) {
    return dispatch({
      type: CLEAR_TRANSACTION_LIST,
      customerList: [],
      customerListFieldControl: [],
      userMembershipStatus: [],
    });
  };
}

export function clearCustomerListFieldControl() {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_CUSTOMER_LIST_FIELD_CONTROL,
      customerListFieldControl: [],
      userMembershipStatus: [],
      deductedMoreThanAvailable: false,
    });
  };
}

export function setUserNotFound(value) {
  return function (dispatch) {
    return dispatch({
      type: USER_NOT_FOUND,
      value: value,
    });
  };
}

export function setDealerNotFound(value) {
  return function (dispatch) {
    return dispatch({
      type: DEALER_NOT_FOUND,
      value: value,
    });
  };
}

export function setDealerExistValue(value) {
  return function (dispatch) {
    return dispatch({
      type: DEALER_EXIST,
      dealerExistValue: value,
    });
  };
}
export function getTransaction(userId, dealerId, fromDate, toDate, tableOrder) {
  return function (dispatch, getState) {
    dispatch({
      type: GET_TRANSACTION_HISTORY,
      transactionHistory: getState().loyaltyProgram.transactionHistory,
      transactionLoad: true,
    });
    if (dealerId == null) {
      dispatch(
        requestTransactionByUserId(userId, fromDate, toDate, tableOrder)
      );
    } else if (userId == null) {
      dispatch(
        requestTransactionByDealerId(dealerId, fromDate, toDate, tableOrder)
      );
    }
  };
}

export function removeCostumerEndTransaction(orderNumber) {
  return function (dispatch, getState) {
    const tempTransactionHistory =
      getState().loyaltyProgram.transactionHistory.filter(
        (item, index) => index !== orderNumber
      );
    const tempCustomer = getState().loyaltyProgram.customerList.filter(
      (item, index) => index !== orderNumber
    );
    dispatch({
      type: REMOVE_TRANSACTION,
      transactionHistory: tempTransactionHistory,
      customerList: tempCustomer,
    });
  };
}

export function cancelTransaction(
  isAllTable,
  transactionNumber,
  actionType,
  point,
  orderNumber,
  transactionOrder
) {
  return function (dispatch, getState) {
    dispatch({
      type: TRANSACTION_CANCEL,
      transactionCancelError: null,
      transactionCancelLoader: true, // active loader
    });

    NetworkServices.requestData(
      "POST",
      Endpoints.cancelTransaction,
      {
        TransactionId: transactionNumber,
      },
      false,
      false
    ).then((response) => {
      if (response.status === 200 && response.data.code !== 0) {
        dispatch({
          type: TRANSACTION_CANCEL,
          transactionCancelError: 200,
          transactionCancelLoader: false,
        });
        if (isAllTable) {
          const tempCustomer = getState().loyaltyProgram.customerList;
          const tempTransaction = getState().loyaltyProgram.transactionHistory;
          global.transactionActionType.Add === actionType
            ? (tempTransaction[transactionOrder].totalPoints -= point)
            : (tempTransaction[transactionOrder].totalPoints += point);

          tempTransaction[transactionOrder].isCancelled = true; // setCancel flag true

          dispatch({
            type: SET_COSTUMER_AND_TRANSACTION, // use it because reducer only set customerList
            customerList: tempCustomer,
            transactionHistory: tempTransaction,
          });
        } else {
          const tempCustomer = getState().loyaltyProgram.customerList;
          const tempTransaction = getState().loyaltyProgram.transactionHistory;
          global.transactionActionType.Add === actionType
            ? (tempCustomer[orderNumber].pointsAvailable -= point)
            : (tempCustomer[orderNumber].pointsAvailable += point);

          tempTransaction[orderNumber].data[
            transactionOrder
          ].isCancelled = true; // setCancel flag true

          dispatch({
            type: SET_COSTUMER_AND_TRANSACTION, // use it because reducer only set customerList
            customerList: tempCustomer,
            transactionHistory: tempTransaction,
          });
        }
      } else {
        return dispatch({
          type: TRANSACTION_CANCEL,
          transactionCancelError: 500,
          transactionCancelLoader: false,
        });
      }
    });
  };
}

export function setUserExistValue(value) {
  return function (dispatch) {
    return dispatch({
      type: USER_EXIST,
      userExitValue: value,
    });
  };
}
export function clearUserList() {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_USER_LIST,
    });
  };
}
export function clearTransactionHistory() {
  return function (dispatch, getState) {
    return dispatch({
      type: CLEAR_TRANSACTION_HISTORY,
      transactionHistory: [],
    });
  };
}

function requestTransactionByUserId(userId, fromDate, toDate, tableOrder) {
  return function (dispatch, getState) {
    const newState = getState().loyaltyProgram.transactionHistory;
    return NetworkServices.requestData(
      "POST",
      Endpoints.getTransaction,
      {
        UserId: userId,
        FromDate: fromDate,
        ToDate: moment(toDate).add(1, "days").subtract(1, "seconds"),
      },
      false,
      false
    ).then((response) => {
      if (tableOrder === undefined || tableOrder === null) {
        newState.push(response.data);
      } else {
        newState[tableOrder] = response.data;
      }

      return dispatch({
        type: GET_TRANSACTION_HISTORY,
        transactionHistory: newState,
        transactionLoad: false,
      });
    });
  };
}

function requestTransactionByDealerId(dealerId, fromDate, toDate, tableOrder) {
  return function (dispatch, getState) {
    const newState = getState().loyaltyProgram.transactionHistory;
    return NetworkServices.requestData(
      "POST",
      Endpoints.getTransactionByDealer,
      {
        DealerId: dealerId,
        FromDate: fromDate,
        ToDate: moment(toDate).add(1, "days").subtract(1, "seconds"),
      },
      false,
      false
    ).then((response) => {
      if (tableOrder === undefined || tableOrder == null) {
        newState.push(response.data);
      } else {
        newState[tableOrder] = response.data;
      }

      return dispatch({
        type: GET_TRANSACTION_HISTORY,
        transactionHistory: newState,
        transactionLoad: false,
      });
    });
  };
}

export function getDealers(dealerId) {
  return function (dispatch, getState) {
    let newCustomerList = [];
    dispatch({
      type: GET_DEALERS,
      customerListDB: [],
      loadingGetCustomers: true,
      showUserNotBeDeleted: false,
      showUserNotBeUpdated: false,
      showCannotDeleteActiveUsersError: false,
      error: {
        status: false,
        message: "",
      },

      clearFilterButtonClicked: false,
    });
    return NetworkServices.requestData(
      "GET",
      Endpoints.IsDealerInTransactionHistory + "?dealerId=" + dealerId,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1 && response.data.data) {
          newCustomerList.push({
            order: newCustomerList.length,
            providerID: getState().auth.userInfo.sub,
            location: getState().auth.userInfo.location_name || " ",
            transactionIssuer: getState().auth.userInfo.working_org_alias,
            emailAddress: "",
            phoneNumber: "",
            loyaltyCardNumber: "",
            serviceValue: null,
            pointsAvailable: "",
            changePoints: 0,
            serviceDate: getCurrentDateWithTimezone(),
            transactionType: null,
            invoice: null,
            actionType: 1,
            dealerId: dealerId,
            transactionFromDate: getGivenDateUTC(moment().subtract(1, "M")),
            transactionToDate: getCurrentDateUTC(),
            previewInvoice: null,
          });

          return dispatch({
            type: GET_DEALERS,
            customerListDB: newCustomerList,
            loadingGetCustomers: false,
            showUserNotBeDeleted: false,
            showUserNotBeUpdated: false,
            showCannotDeleteActiveUsersError: false,
            userDeleted: false,
            userUpdated: false,
            error: {
              status: false,
            },
            clearFilterButtonClicked: false,
          });
        } else {
          return dispatch({
            type: GET_DEALERS,
            loadingGetCustomers: false,
            customerListDB: [],
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_DEALERS,
          loadingGetCustomers: false,
          customerListDB: [],
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

export function GetPagedTransactions(fromDate, toDate) {
  return function (dispatch, getState) {
    // eslint-disable-next-line no-unused-vars
    let newCustomerList = [];
    dispatch({
      type: GET_PAGED_ALL_TRANSACTIONS,
      transactionHistory: [],
      loadingGetAllTransaction: true,
      transactionHistoryFromDate: fromDate,
      transactionHistoryToDate: toDate,
      pagination: _.cloneDeep(getState().loyaltyProgram.pagination),
      error: {
        status: false,
        message: "",
      },
    });

    let currentPage = getState().loyaltyProgram.pagination.currentPage;
    const requestBody = {
      page: currentPage,
      pageSize: global.transactionHistoryPageSize,
      fromDate: fromDate,
      toDate: moment(toDate).add(1, "days").subtract(1, "seconds"),
    };
    return NetworkServices.requestData(
      "POST",
      Endpoints.GetPagedTransactions,
      requestBody,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1 && response.data.data.results) {
          return dispatch({
            type: GET_PAGED_ALL_TRANSACTIONS,
            transactionHistory: response.data.data.results,
            loadingGetAllTransaction: false,
            transactionHistoryFromDate: fromDate,
            transactionHistoryToDate: toDate,
            error: {
              status: false,
            },
            pagination: {
              currentPage: response.data.data.currentPage,
              pageCount: response.data.data.pageCount,
              pageSize: response.data.data.pageSize,
              rowCount: response.data.data.rowCount,
              firstRowOnPage: response.data.data.firstRowOnPage,
              lastRowOnPage: response.data.data.lastRowOnPage,
            },
          });
        } else {
          return dispatch({
            type: GET_PAGED_ALL_TRANSACTIONS,
            loadingGetAllTransaction: false,
            transactionHistory: [],
            transactionHistoryFromDate: fromDate,
            transactionHistoryToDate: toDate,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_PAGED_ALL_TRANSACTIONS,
          loadingGetAllTransaction: false,
          transactionHistory: [],
          transactionHistoryFromDate: fromDate,
          transactionHistoryToDate: toDate,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

export function changeActivePage(page) {
  return function (dispatch, getState) {
    if (page !== getState().loyaltyProgram.pagination.currentPage) {
      let paginationObj = getState().loyaltyProgram.pagination;
      paginationObj.currentPage = page;
      return dispatch({
        type: CHANGE_HISTORY_ACTIVE_PAGE,
        pagination: paginationObj,
      });
    }
  };
}

export function clearPagination() {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_PAGINATION,
    });
  };
}

export function downloadInvoice(transactionId, userId, invoice) {
  return function (dispatch, getState) {
    return NetworkServices.requestData(
      "GET",
      Endpoints.GetBlobSasUri + "?filename=" + invoice,
      null,
      false,
      false
    )
      .then((response) => {
        NetworkServices.downloadInvoice(response.data.data)
          .then((response) => {
            var filename =
              "invoice-transaction-" + transactionId + "--" + invoice;
            downloadFile(response.data, filename, "pdf");
            return dispatch({
              type: DOWNLOAD_INVOICE,
            });
          })
          .catch(() => {
            return dispatch({
              type: DOWNLOAD_INVOICE,
            });
          });
      })
      .catch(() => {
        return dispatch({
          type: DOWNLOAD_INVOICE,
        });
      });
  };
}

export function searchVoucherCode(voucherCode) {
  return function (dispatch, getState) {
    var voucherSearchList = [];
    dispatch({
      type: SEARCH_VOUCHERCODE,
      loadingVoucherCode: true,
      voucherSearchList: voucherSearchList,
    });

    return NetworkServices.requestData(
      "GET",
      Endpoints.VoucherCodesByCode + "?code=" + voucherCode,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1 && response.data.data) {
          return dispatch({
            type: SEARCH_VOUCHERCODE,
            loadingVoucherCode: false,
            voucherSearchList: response.data.data,
          });
        }
      })
      .catch(() => {
        return dispatch({
          type: SEARCH_VOUCHERCODE,
          loadingVoucherCode: false,
          voucherSearchList: voucherSearchList,
        });
      });
  };
}

export function clearVoucherCode() {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_VOUCHERCODE,
      voucherSearchList: [],
    });
  };
}

export function addVoucherCode() {
  return function (dispatch, getState) {
    let newVoucherCodeList = getState().loyaltyProgram.voucherCodeList;
    let newSelectedVoucherCodeList =
      getState().loyaltyProgram.selectedVoucherCodeList;
    let searchResult = getState().loyaltyProgram.voucherSearchList;

    if (newVoucherCodeList.length <= 30) {
      newVoucherCodeList.push(searchResult);
      newSelectedVoucherCodeList.push(searchResult);
    }

    return dispatch({
      type: ADD_VOUCHERCODE,
      voucherCodeList: newVoucherCodeList,
      selectedVoucherCodeList: newSelectedVoucherCodeList,
    });
  };
}

export function setVoucherNotFound(value) {
  return function (dispatch) {
    return dispatch({
      type: VOUCHER_NOT_FOUND,
      voucherNotFound: value,
    });
  };
}

export function setVoucherExistValue(value) {
  return function (dispatch) {
    return dispatch({
      type: VOUCHER_EXIST,
      voucherExistValue: value,
    });
  };
}

export function clearVoucherCodeList() {
  return function (dispatch, getState) {
    return dispatch({
      type: CLEAR_VOUCHERCODE_LIST,
      voucherCodeList: [],
      voucherCodeListForExport: [],
      selectedVoucherCodeList: [],
    });
  };
}

export function removeVoucherCodeFromList(orderNumber) {
  return function (dispatch, getState) {
    const tempVoucherCodeList =
      getState().loyaltyProgram.voucherCodeList.filter(
        (item, index) => index !== orderNumber
      );
    const tempSelectedVoucherCodeList =
      getState().loyaltyProgram.selectedVoucherCodeList.filter(
        (item, index) => index !== orderNumber
      );
    dispatch({
      type: REMOVE_VOUCHERCODE,
      voucherCodeList: tempVoucherCodeList,
      selectedVoucherCodeList: tempSelectedVoucherCodeList,
    });
  };
}

export function GetPagedVoucherCodeList(fromDate, toDate) {
  return function (dispatch, getState) {
    dispatch({
      type: GET_PAGED_ALL_VOUCHERCODES,
      voucherCodeList: [],
      loadingVoucherCode: true,
      pagination: _.cloneDeep(getState().loyaltyProgram.pagination),
      voucherCodeListFromDate: fromDate,
      voucherCodeListToDate: toDate,
      error: {
        status: false,
        message: "",
      },
    });

    let currentPage = getState().loyaltyProgram.pagination.currentPage;
    const requestBody = {
      page: currentPage,
      pageSize: global.voucherCodeListPageSize,
      fromDate: fromDate,
      toDate: moment(toDate).add(1, "days").subtract(1, "seconds"),
    };
    return NetworkServices.requestData(
      "POST",
      Endpoints.GetPagedVoucherCodeList,
      requestBody,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1 && response.data.data.results) {
          return dispatch({
            type: GET_PAGED_ALL_VOUCHERCODES,
            voucherCodeList: response.data.data.results,
            loadingVoucherCode: false,
            voucherCodeListFromDate: fromDate,
            voucherCodeListToDate: toDate,
            error: {
              status: false,
            },
            pagination: {
              currentPage: response.data.data.currentPage,
              pageCount: response.data.data.pageCount,
              pageSize: response.data.data.pageSize,
              rowCount: response.data.data.rowCount,
              firstRowOnPage: response.data.data.firstRowOnPage,
              lastRowOnPage: response.data.data.lastRowOnPage,
            },
          });
        } else {
          return dispatch({
            type: GET_PAGED_ALL_VOUCHERCODES,
            loadingVoucherCode: false,
            voucherCodeList: [],
            voucherCodeListFromDate: fromDate,
            voucherCodeListToDate: toDate,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_PAGED_ALL_VOUCHERCODES,
          loadingVoucherCode: false,
          voucherCodeList: [],
          voucherCodeListFromDate: fromDate,
          voucherCodeListToDate: toDate,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}

export function GetAllVoucherCodeListForExport() {
  return function (dispatch, getState) {
    dispatch({
      type: GET_ALL_VOUCHERCODES,
      voucherCodeListForExport: [],
      loadingVoucherCodeExport: true,
      isFilledVoucherCodeExport: false,
      error: {
        status: false,
        message: "",
      },
    });

    const requestBody = {
      page: 1,
      pageSize: getState().loyaltyProgram.pagination.rowCount
        ? getState().loyaltyProgram.pagination.rowCount
        : global.voucherCodeListPageSize,
      fromDate: getState().loyaltyProgram.voucherCodeListFromDate,
      toDate: moment(getState().loyaltyProgram.voucherCodeListToDate)
        .add(1, "days")
        .subtract(1, "seconds"),
    };
    return NetworkServices.requestData(
      "POST",
      Endpoints.GetPagedVoucherCodeList,
      requestBody,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1 && response.data.data.results) {
          return dispatch({
            type: GET_ALL_VOUCHERCODES,
            voucherCodeListForExport: response.data.data.results,
            loadingVoucherCodeExport: false,
            isFilledVoucherCodeExport: true,
            error: {
              status: false,
            },
          });
        } else {
          return dispatch({
            type: GET_ALL_VOUCHERCODES,
            loadingVoucherCodeExport: false,
            voucherCodeListForExport: [],
            isFilledVoucherCodeExport: true,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_ALL_VOUCHERCODES,
          loadingVoucherCodeExport: false,
          voucherCodeListForExport: [],
          isFilledVoucherCodeExport: true,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}
export function ClearIsFilledVoucherCodeExport() {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_ISFILLED_VOUCHERCODEEXPORT,
      isFilledVoucherCodeExport: false,
    });
  };
}

export function GetAllTransactionHistoryForExport() {
  return function (dispatch, getState) {
    dispatch({
      type: GET_ALL_TRANSACTIONS,
      transactionHistoryForExport: [],
      loadingTransactionHistoryExport: true,
      isFilledTransactionHistoryExport: false,
      error: {
        status: false,
        message: "",
      },
    });

    const requestBody = {
      page: 1,
      pageSize: getState().loyaltyProgram.pagination.rowCount
        ? getState().loyaltyProgram.pagination.rowCount
        : global.transactionHistoryPageSize,
      fromDate: getState().loyaltyProgram.transactionHistoryFromDate,
      toDate: moment(getState().loyaltyProgram.transactionHistoryToDate)
        .add(1, "days")
        .subtract(1, "seconds"),
    };
    return NetworkServices.requestData(
      "POST",
      Endpoints.GetPagedTransactions,
      requestBody,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1 && response.data.data.results) {
          return dispatch({
            type: GET_ALL_TRANSACTIONS,
            transactionHistoryForExport: response.data.data.results,
            loadingTransactionHistoryExport: false,
            isFilledTransactionHistoryExport: true,
            error: {
              status: false,
            },
          });
        } else {
          return dispatch({
            type: GET_ALL_TRANSACTIONS,
            loadingTransactionHistoryExport: false,
            transactionHistoryForExport: [],
            isFilledTransactionHistoryExport: true,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: GET_ALL_TRANSACTIONS,
          loadingTransactionHistoryExport: false,
          transactionHistoryForExport: [],
          isFilledTransactionHistoryExport: true,
          error: {
            status: true,
            message: "Error: " + error,
          },
        });
      });
  };
}
export function ClearIsFilledTransactionHistoryExport() {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_ISFILLED_TRANSACTIONHISTORY_EXPORT,
      isFilledTransactionHistoryExport: false,
    });
  };
}

export function handleChangeVoucherStatus(value, tableOrder, index) {
  return function (dispatch, getState) {
    let newVoucherCodeList = _.cloneDeep(
      getState().loyaltyProgram.voucherCodeList
    );
    let selectedVoucherCodeList = _.cloneDeep(
      getState().loyaltyProgram.selectedVoucherCodeList
    );

    newVoucherCodeList[tableOrder][index].status = parseInt(value);
    if (value === global.voucherCodeStatusEnum[0].value) {
      //used
      newVoucherCodeList[tableOrder][index].dateOfUse = moment
        .utc()
        .format("X");
    } else {
      //not used
      newVoucherCodeList[tableOrder][index].dateOfUse = null;
    }
    return dispatch({
      type: HANDLE_CHANGE_VOUCHER_STATUS,
      voucherCodeList: newVoucherCodeList,
      selectedVoucherCodeList: selectedVoucherCodeList,
    });
  };
}

export function updateVoucherCodeStatus() {
  return function (dispatch, getState) {
    let voucherCodeList = _.cloneDeep(
      getState().loyaltyProgram.voucherCodeList
    );
    let voucherCodeListToUpdate = [];

    voucherCodeList.forEach((voucherCodes) => {
      voucherCodes.forEach((item) => {
        voucherCodeListToUpdate.push(item);
      });
    });

    return NetworkServices.requestData(
      "POST",
      Endpoints.UpdateVoucherCodeStatus,
      voucherCodeListToUpdate,
      false,
      false
    )
      .then((response) => {
        if (response.data.code === 1) {
          return dispatch({
            type: UPDATE_VOUCHER_CODE_STATUS,
            voucherCodeList: voucherCodeList,
            selectedVoucherCodeList: voucherCodeList,
          });
        } else {
          return dispatch({
            type: UPDATE_VOUCHER_CODE_STATUS,
            voucherCodeList: voucherCodeList,
            selectedVoucherCodeList: _.cloneDeep(
              getState().loyaltyProgram.selectedVoucherCodeList
            ),
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_VOUCHER_CODE_STATUS,
          voucherCodeList: voucherCodeList,
          selectedVoucherCodeList: _.cloneDeep(
            getState().loyaltyProgram.selectedVoucherCodeList
          ),
        });
      });
  };
}
